import { useMemo } from 'react';
import IconStar from 'assets/images/star.svg';
import StarOff from 'assets/start-outline.svg';
import IconCalendar from 'assets/calendar.svg';
import IconLocation from 'assets/location.svg';
import ImageEmpty from 'assets/darkLogo.png';
import { Typography } from '@mui/material';
import stateData from 'constant/state.json';
import cityData from 'constant/city.json';
import ReactHtmlParser from 'react-html-parser';
import { formatDescription } from 'utils/helper';

export default function (props) {
  const {
    title,
    posted,
    location,
    desc,
    image,
    onClick,
    className,
    onQuickApply,
    onAddFavourite,
    isFavorite,
    id,
    quickApplyTitle,
    cityId,
    stateId,
    user,
    disableButton
  } = props;

  const stateName = useMemo(
    () => stateData?.find((item) => item.state_id == stateId)?.state_name ?? '',
    [stateId]
  );

  const cityName = useMemo(
    () => cityData[2].data.find((city) => city.city_id == cityId)?.city_name ?? '',
    [cityId]
  );

  const renderDesc = useMemo(() => ReactHtmlParser(desc), [desc]);

  return (
    <div className={`card-job ${className}`}>
      <div className="job-image">
        {/* <img src={image} alt="" /> */}
        <div
          className="image"
          style={{
            backgroundImage: `url(${image}), url(${ImageEmpty})`
          }}></div>
      </div>
      <div className="job-body">
        <div className="job-header">
          <div className="pointer" onClick={onClick}>
            <Typography variant="h3" className="job-title">
              {title}
            </Typography>
          </div>
          <div className="job-act">
            <div className="pr-1">
              <button className={`${isFavorite} ${id} btn fav-btn`} onClick={onAddFavourite}>
                {isFavorite ? <img src={IconStar} alt="" /> : <img src={StarOff} alt="" />}
              </button>
            </div>
            <div>
              <button
                className="btn btn-primary"
                style={{
                  backgroundColor: quickApplyTitle === 'Applied' ? '#2b4257' : '#2b4257'
                }}
                onClick={onQuickApply}
                disabled={disableButton || quickApplyTitle === 'Applied' ? true : false}>
                {quickApplyTitle ? quickApplyTitle : 'Quick Apply'}
              </button>
            </div>
          </div>
        </div>
        <div className="job-meta">
          <div className="meta-item">
            <img src={IconCalendar} alt="" /> <span>{posted}</span>
          </div>
          {stateName && (
            <div className="meta-item">
              <img src={IconLocation} alt="" />
              <span>{`${stateName}, ${cityName}`}</span>
            </div>
          )}
        </div>
        <div className="job-content">
          {user !== null ? formatDescription(desc) : ReactHtmlParser(renderDesc)}
        </div>
        {/* <div className="job-content"> {ReactHtmlParser(renderDesc)}</div> */}
        {/* {ReactHtmlParser("<a class='github' href='https://github.com'><b>GitHub</b></a>")} */}
      </div>
    </div>
  );
}
